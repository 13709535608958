import React, { Component } from 'react';
import styled from "@emotion/styled";
import colors from "../../styles/colors";
import dimensions from "../../styles/dimensions";

const ButtonContainer = styled("button")`
    padding: 0.8em 1.8em;
    background: #ffffff;
    font-weight: 600;
    color: ${colors.omind};
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    position: relative;
    transition: all 150ms ease-out;

    border: 2px solid ${colors.omind};
    box-shadow: 0px 14px 49px rgba(0, 0, 0, 0.07);

    @media(max-width:${dimensions.maxwidthMobile}px) {
        padding: 0.8em 1.8em;
        font-size: 1em;
    }

    p {
        margin: 0;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: -1;
    }

    &:hover {
        cursor: pointer;
        transition: all 150ms ease-out;
        box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.15);
        color: ${colors.omind};
        transform: translateY(-0.25em);
    }

    &.Button--secondary {
        background: ${colors.omind};
        color: #fff;
        padding: 0.95em 1.8em;
        font-size: 0.95rem;

        &:hover {
            background: #fff;
            transition: all 150ms ease-out;
            box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.15);
            color: ${colors.omind};
        }
    }
`

class Button extends Component {
    render() {
        const { children, ...props } = this.props;
        return (
            <ButtonContainer
                onClick={this.props.onClick}
                {...props}>
                {this.props.children}
            </ButtonContainer>
        );
    }
}

export default Button;